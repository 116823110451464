import styled from '@emotion/styled'
import React from 'react'
import { Link } from 'gatsby'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0092 = (props: Props): JSX.Element => (
  <Wrapper>
    <ColumnH1 label="勤怠管理のワークフローはシステム化できる！メリットや選び方を解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2024.03.21</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <img src="/images/column/details/c0092.jpg" alt="キンクラコラム画像92" />
      <p>
        勤怠管理における申請や承認、決済などの手続きに無駄があると、業務効率が下がってしまう可能性があります。このような問題を解決する方法として、ワークフロー機能が付いた勤怠管理システムの導入がおすすめです。
        <br />
        しかし、具体的にどのようなシステムを導入すればいいのか分からないという方も多いでしょう。この記事では、そもそもワークフローとは何か、またワークフロー機能付きの勤怠管理システムを選定する際のポイントなどを解説します。
      </p>
      <div id="toc1">
        <h1>ワークフローとは何か？ワークフローを効率化する方法とは</h1>
      </div>
      <p>
        まずはワークフローの意味やワークフローを効率化する方法について解説します。
      </p>
      <div id="toc1-1">
        <h2>そもそもワークフローとは</h2>
      </div>
      <p>
        仕事には「始まりから終わりまでに誰が何を行い、どのように処理をして完了させるのか」という流れがあります。
        <br />
        この業務の一連の流れのことをワークフローといいます。
        <br />
        例えば、従業員に給与が支払われるまでの流れは次の通りです。
        <br />
        1.従業員が出退勤や残業など勤務時間の申請をする。
        <br />
        2.管理部署や上司が承認を行う。
        <br />
        3.給与計算の担当部署が事務手続きをすることで給与支払いが実施される。
        <br />
        このように、社内の業務には一定のワークフローが存在しています。
        <br />
        ワークフローに無駄が生じていると業務の効率が下がってしまうため、ワークフローを適宜見直していく必要があります。
      </p>
      <div id="toc1-2">
        <h2>ワークフローはシステム化で効率化できる</h2>
      </div>
      <p>
        ワークフローの運用方法は企業によって異なりますが、紙媒体で管理をしている企業も少なくありません。紙による運用では、書類の作成や保管に手間がかかり、紛失などのリスクも高いです。
        <br />
        さらに、ワークフローが不明瞭な状態になり、従業員の管理が難しくなる場合もあります。
        <br />
        申請や報告の漏れ、手続きの不備などの問題が起きた場合は、迅速な対処が必要です。このような問題は、ワークフローをシステム化することで解決できます。
      </p>
      <div id="toc1-3">
        <h2>ワークフロー機能を備えた勤怠管理システムによる効率化がおすすめ</h2>
      </div>
      <p>
        ワークフローシステムには様々なものがありますが、特におすすめしたいのはワークフロー機能付きの勤怠管理システムです。
        <br />
        ワークフロー機能を備えた勤怠管理システムを導入すれば、日々の出退勤管理はもちろん、休日出勤や残業の記録、遅刻や早退の申請、休暇や代休申請などを一元管理可能です。さらに、申請の承認や給与計算システムとの連携を行うことができます。
        <br />
        <br />
        以上のように、ワークフローはシステムを導入することによって効率化できますが、更に効率を上げるために、ワークフロー機能を備えた勤怠管理システムを導入するのがおすすめです。
      </p>
      <div id="toc2">
        <h1>ワークフロー機能付きの勤怠管理システムを導入するメリット</h1>
      </div>
      <img
        src="/images/column/details/c0092_g01.jpg"
        alt="キンクラコラム画像92"
      />
      <p>
        ワークフロー機能付きの勤怠管理システムを導入することで、さまざまなメリットがあります。
        <br />
        そのメリットとは何か？いくつかご紹介します。
      </p>
      <div id="toc2-1">
        <h2>複雑な作業を簡略化できる</h2>
      </div>
      <p>
        ワークフロー機能を備えた勤怠管理システムを導入することで複雑な作業を簡略化できます。従業員ごとに勤務時間や休日、残業などの状況が異なるため、勤怠管理や集計には膨大な手間がかかります。しかし、システムを導入することで、集計作業の手間を削減でき、業務の効率化が可能です。
      </p>
      <Link
        to={useRouteParam('https://www.kintaicloud.jp/column/details/C0069/')}
        className="column-detail-link"
      >
        関連記事：手動での勤怠管理からの脱却！無料の勤怠管理システムのメリットとは？
      </Link>
      <div id="toc2-2">
        <h2>業務の流れを可視化できる</h2>
      </div>
      <p>
        一連の業務を可視化できるのも、メリットのひとつです。
        <br />
        例えば何かしらの申請作業を行う場合、ワークフローが可視化されることによって、「誰に承認をもらえばいいのか？」が分かりやすくなります。
        <br />
        それにより、スムーズに承認作業をすることが可能になります。
      </p>
      <div id="toc2-3">
        <h2>他システムとの連携ができる</h2>
      </div>
      <p>
        システムによっては、社内の既存システムと連携させて使うことが可能です。
        <br />
        すべての情報を一元管理できるためチェックミスも減り、スピーディーかつ効率的に業務を処理できるでしょう。
      </p>
      <div id="toc2-4">
        <h2>ペーパーレス化につながる</h2>
      </div>
      <p>
        勤怠管理システムを導入すれば、申請や承認、決裁など一連のワークフローをすべてシステム上で行えます。
        <br />
        紙の書類を作成し、印刷したりファイリングしたりする手間もかかりません。
        <br />
        ペーパーレス化によって書類の回収や処理の手間を省けば、より効率的に業務を進められます。
        <br />
        また、書類の保管スペースが必要なくなるのも嬉しいところです。
        <br />
        <br />
        以上のように、ワークフローをシステム化することによって様々なメリットが得られます。
      </p>
      {/** ここに9パターンの広告設定する */}
      {props.no === 0 && <Introduction004 />}
      {props.no === 1 && <Introduction005 />}
      {props.no === 2 && <Introduction006 />}
      {props.no === 3 && <Introduction007 />}
      {props.no === 4 && <Introduction008 />}
      {props.no === 5 && <Introduction009 />}
      {props.no === 6 && <Introduction010 />}
      {props.no === 7 && <Introduction011 />}
      {props.no === 8 && <Introduction012 />}
      <div id="toc3">
        <h1>ワークフロー機能付きの勤怠管理システムを選ぶ際のポイント</h1>
      </div>
      <img
        src="/images/column/details/c0092_g02.jpg"
        alt="キンクラコラム画像92"
      />
      <p>
        ワークフロー機能付き勤怠管理システムを選定する際にはどのようなことに気を付ければよいのか、いくつかポイントを解説します。
      </p>
      <Link
        to={useRouteParam('https://www.kintaicloud.jp/column/details/C0067/')}
        className="column-detail-link"
      >
        関連記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
      </Link>
      <div id="toc3-1">
        <h2>クラウド型を導入する</h2>
      </div>
      <p>
        ワークフロー機能付きの勤怠管理システムは、クラウド型がおすすめです。
        <br />
        クラウド型は導入コストを抑えることができ、設定やメンテナンスにもそれほど手間がかかりません。また、システムの提供会社が保守対応やセキュリティ対策をしてくれるのもクラウド型の魅力です。複雑な設定をせず、すぐに運用できるクラウド型サービスで、効率的よくシステムを運用しましょう。
      </p>
      <Link
        to={useRouteParam('https://www.kintaicloud.jp/column/details/C0067/')}
        className="column-detail-link"
      >
        関連記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
      </Link>
      <div id="toc3-2">
        <h2>操作はしやすいか</h2>
      </div>
      <p>
        操作性の悪いシステムを導入した結果「使いにくい」と敬遠されてしまうケースもあります。システムを導入するときには、すべての従業員が負担なく使えるかを確認しておきましょう。直感的に操作できるものを選べば、申請や承認にかかる時間を削減できます。
      </p>
      <div id="toc3-3">
        <h2>コスト面</h2>
      </div>
      <p>
        勤怠管理システムを導入するにあたってはコスト面が気になると思います。
        勤怠管理システムの料金プランは企業の規模や利用人数、機能の幅によって変動します。
        単に安いものを選んで導入するのではなく、必要な機能が過不足なく備わっているかを確認しましょう。
        <br />
        <br />
        上記のポイントを押さえることによって、自社に最適なワークフロー付きの勤怠管理システムを導入することが出来ます。
      </p>
      <Link
        to={useRouteParam('https://www.kintaicloud.jp/column/details/C0090/')}
        className="column-detail-link"
      >
        関連記事：無料で使える勤怠管理アプリとは？メリットや注意点も紹介
      </Link>
      <div id="toc4">
        <h1>ワークフロー機能付きの勤怠管理システムなら「キンクラ」</h1>
      </div>
      <p>
        ここまでで、ワークフロー機能付きの勤怠管理システムを導入するメリットや選定する際のポイントを解説していきました。
        ワークフロー機能付きの勤怠管理システムを導入する際には、この記事で紹介したことを参考にすることで自社に最適なシステムを導入することが出来ます。
        もし、ワークフロー機能付きの勤怠管理システムの導入を検討しているならば「キンクラ」もおすすめめです。
        <br />
        キンクラは、全機能が無料でお試しできるトライアルがあります。
        そのため、実際に導入してみて、自社に合った勤怠管理システムなのかどうかを試すことが出来ます。
      </p>
      <Link
        to={useRouteParam('https://www.kintaicloud.jp/column/details/C0090/')}
        className="column-detail-link"
      >
        ⇒ 勤怠管理システム「キンクラ」について詳しく知りたい方はここをクリック
      </Link>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0092
